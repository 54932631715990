import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/Personal-Website/Personal-Website/src/components/layout/MD_Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Network Benchmarking With Python`}</h1>
    <p>{`If you are looking to set up a quick and easy way to benchmark your network
connection using Python look no further. Using the `}<a parentName="p" {...{
        "href": "https://pypi.org/project/speedtest-cli/"
      }}>{`speedtest-cli`}</a>{`
package you can monitor your network usage around the clock.`}</p>
    <p>{`To start we will by installing the speedtest package.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pip3 install speedtest
`}</code></pre>
    <p>{`Then importing the packages we are using.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import speedtest
import time
import datetime
`}</code></pre>
    <p>{`Following the initializing of the Speedtest Object we will start an infinite
loop to collect the network data. On lines 11 and 12 we access the test
object and call the `}<inlineCode parentName="p">{`download()`}</inlineCode>{` and `}<inlineCode parentName="p">{`upload()`}</inlineCode>{` methods to get the
network speeds. These values, which are returned in
`}<a parentName="p" {...{
        "href": "http://www.wu.ece.ufl.edu/links/dataRate/DataMeasurementChart.html"
      }}>{`bytes`}</a>{`,
are then appended to our CSV file with the timestamp of when it was taken.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`test = speedtest.Speedtest()  # Initialize the test object

while True:

  test.get_best_server()  # Make sure the best server is being used

  for _ in range(12):  # Check for better server every two hours

    # Generate the data
    current_time = datetime.datetime.now()
    download_speed = test.download()
    upload_speed = test.upload()

    csv_line = ",".join(map(str, [current_time, download_speed, upload_speed])) + "\\n"

    try:
      with open(DATA_PATH, "a") as fp:
        fp.write(csv_line)

    except:
      print("Oh No!")

    time.sleep(600)  # Rest for 10 minutes before next execution
`}</code></pre>
    <p>{`You can see an
`}<a parentName="p" {...{
        "href": "https://raw.githubusercontent.com/CannonLock/data/master/benchmark.csv"
      }}>{`example of this data`}</a>{`
on my GitHub. If you are interested in the project that
required this data you can refer to my project article `}<a parentName="p" {...{
        "href": "/projects/Charter_Network_Benchmark"
      }}>{`"Charter ISP - Slow
Internet at 5pm or Delusion?"`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      